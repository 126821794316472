import React from "react";
import Navbar from "../components/common/Navbar";

const Dashbaord = () => {
    return (
        <>
            <Navbar />
            <main className="page-wrapper">
                <h1 className="title">Dashboard</h1>
            </main>
        </>
    );
};

export default Dashbaord;
